import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import { ReactLazyPreload } from "./ReactLazyPreload";
import LoadingView from "./components/LoadingView";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import "./index.scss";

const App = ReactLazyPreload(() =>
  import(/* webpackChunkName: "app" */ "./App")
);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingView />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
