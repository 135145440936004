import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import './index.scss';

const LoadingView = () => {
  return (
    <div className="loadingView">
      <BeatLoader color="#6cc3e2" size={20} />
    </div>
  );
};

export default LoadingView;
